import { priorityColorJSON } from "./priorityColor";

export function groupByBrandCategorySubcategory(data) {
    const grouped = {};

    data.forEach(item => {
        // Extract brand, category, and subcategory from details array
        const brand = item.details.find(detail => detail.brand)?.brand || "";
        const category = item.details.find(detail => detail.category)?.category || "";
        const subcategory = item.details.find(detail => detail.subcategory)?.subcategory || "";

        // Create a unique key
        const key = `${brand}-${category}-${subcategory}`;

        if (!grouped[key]) {
            // Initialize the group
            grouped[key] = {
                brand,
                category,
                subcategory,
                count: item.count,
                detections: [...item.detections.map(res => res.id)]
            };
        } else {
            // Merge detections and increment the count
            grouped[key].detections.push(...item.detections.map(res => res.id));
            grouped[key].count += item.count;
        }
    });

    // Convert grouped object back to array
    return Object.values(grouped)
    .map((group) => ({
        brand: group.brand,
        category: group.category,
        subcategory: group.subcategory,
        count: group.count,
        detections: group.detections
    }))
    .sort((a, b) => {
        // Exception: If brand is 'other', place it at the end
        if (a.brand.toLowerCase() === 'other' && b.brand.toLowerCase() !== 'other') {
            return 1; // Move 'a' down
        }

        // Primary sort: descending order of count
        if (b.count !== a.count) {
            return b.count - a.count;
        }

        // Secondary sort: ascending order of brand (case-insensitive)
        return a.brand.toLowerCase().trim().localeCompare(b.brand.toLowerCase().trim());
    }).map((res, index) => ({
        ...res,
        'color': priorityColorJSON[(index > 20 ? 20: index).toString()],
    }));
}
