export const priorityColorJSON = {
	"0": "#7F1D1D",  // Maroon
	"1": "#8B4513",  // Chocolate Brown
	"2": "#065F46",  // Forest Green
	"3": "#4D7C0F",  // Olive Green
	"4": "#4D7C0F",  // Moss Green
	"5": "#1E3A8A",  // Dark Blue
	"6": "#1E40AF",  // Navy Blue
	"7": "#5B21B6",  // Indigo
	"8": "#6D28D9",  // Dark Purple
	"9": "#4C51BF",  // Slate Blue
	"10": "#DC2626", // Crimson
	"11": "#F87171", // Coral
	"12": "#0FB5BA", // Turquoise
	"13": "#0D9488", // Teal
	"14": "#2DD4BF", // Sea Green
	"15": "#06B6D4", // Aqua
	"16": "#22D3EE", // Cyan
	"17": "#38BDF8", // Light Blue
	"18": "#A78BFA", // Lavender
	"19": "#FB923C", // Peach
	"20": "#B87333"  // Copper
}
